import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Tooltip from '@material-ui/core/Tooltip';
import { useAppDispatch, useAppSelector } from '../../../store';
import { getAllUsersAsync } from '../../../store/slices/usersSlice';
import Search from './search/Search';
import { openModal } from '../../../store/slices/uiSlice';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../../lib/services/images';

const Wrapper = styled.header`
  margin-bottom: 42px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin-bottom: 0px;
    padding: 0px;
  }
`;

const NavBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const ToolBar = styled.nav`
  display: flex;
  align-items: center;
`;
const UserAvatars = styled.nav`
  display: flex;
  flex-shrink: 0;
  margin-left: 24px;

  a {
    flex-shrink: 0;
  }
`;

const flexStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Circle = styled.img`
  ${flexStyles}
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  cursor: pointer;
  flex-shrink: 0;
  object-fit: contain;
  transition: transform 0.15s ease;

  :hover {
    transform: scale(0.96);
  }
`;

const Avatar = styled(Circle)`
  background-color: ${({ color }) => `${color}`};
`;

const NewUser = styled.button`
  ${flexStyles}
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  border-radius: 50%;
  color: #fff;
  border: 2px solid var(--accent);
  cursor: pointer;

  transition: transform 0.15s ease;

  :hover {
    transform: scale(0.96);
  }
`;

function Header({ phone }) {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const users = useAppSelector((state) => state.users);
  const avatars = users.map((user) => {
    if (user.isAdmin) return null;
    if (user.isDeleted) return null;
    return (
      <Tooltip
        key={`user_${user._id}`}
        placement="bottom"
        title={`${user.firstName} ${user.lastName}`}
      >
        <Link to={`/user/${user._id}`}>
          <Avatar src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
        </Link>
      </Tooltip>
    );
  });

  useEffect(() => {
    if (auth && auth._id) {
      dispatch(getAllUsersAsync());
    }
  }, [auth, dispatch]);

  const triggerNewUserModal = () => {
    dispatch(openModal({ type: 'create-user', props: {} }));
  };

  if (!auth) {
    return null;
  }

  return (
    <Wrapper>
      <NavBar>
        <Search />
        {!phone && (
          <ToolBar>
            {auth.isAdmin && (
              <>
                <UserAvatars>{avatars}</UserAvatars>
                {auth.isSuperAdmin && (
                  <Tooltip placement="bottom" title="Create user">
                    <NewUser onClick={triggerNewUserModal}>
                      <AddRoundedIcon style={{ color: 'var(--accent)' }} />
                    </NewUser>
                  </Tooltip>
                )}
              </>
            )}
          </ToolBar>
        )}
      </NavBar>
    </Wrapper>
  );
}

export default React.memo(Header);
