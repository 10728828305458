/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../lib/api/axios';
import { getTimeISO, getTimeUTC } from '../../lib/utils/getTime';

export interface User {
  _id: string;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  avatar?: string;
  createdAt: Date;
  isDeleted?: boolean;
  telegramId: string;
}

type UsersState = User[];

const initialState: UsersState = [];

interface ErrorResponse {
  status: number;
  message: string;
}

interface ThunkApi {
  rejectValue: ErrorResponse;
}

type GetAllUsersReturnData = User[];
type GetAllUsersPayload = undefined;

export const getAllUsersAsync = createAsyncThunk<
  GetAllUsersReturnData,
  GetAllUsersPayload,
  ThunkApi
>('users/getAllUsers', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'get',
      url: '/user',
    });

    return response.data.data.map((user) => {
      const result = user;

      if (result.workFrom) {
        result.workFrom = getTimeISO(result.workFrom);
      }

      if (result.workTo) {
        result.workTo = getTimeISO(result.workTo);
      }

      return result;
    });
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type ChangeAvatarPayload = { userId: string; file: File };

export const changeAvatarAsync = createAsyncThunk<
  User,
  ChangeAvatarPayload,
  ThunkApi
>('users/changeAvatar', async (payload, { rejectWithValue }) => {
  const formData = new FormData();
  formData.append('userId', payload.userId);
  formData.append('fileType', payload.file.type);
  formData.append('fileName', payload.file.name);
  formData.append('file', payload.file);

  try {
    const response = await axios({
      method: 'post',
      url: '/user/avatar',
      data: formData,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type EditUserInfoPayload = {
  userId: string;
  values: {
    firstName: string;
    lastName?: string;
    email?: string;
    phone?: string;
    creditCard?: string;
    workFrom?: string;
    workTo?: string;
  };
};

export const editUserInfoAsync = createAsyncThunk<
  User,
  EditUserInfoPayload,
  ThunkApi
>('users/editUserInfo', async (payload, { rejectWithValue }) => {
  const { userId, values } = payload;

  try {
    if (values.workFrom) {
      values.workFrom = getTimeUTC(values.workFrom);
    }

    if (values.workTo) {
      values.workTo = getTimeUTC(values.workTo);
    }

    const response = await axios({
      method: 'put',
      url: `/user/${userId}`,
      data: values,
    });

    const result = response.data.data;

    if (result.workFrom) {
      result.workFrom = getTimeISO(result.workFrom);
    }

    if (result.workTo) {
      result.workTo = getTimeISO(result.workTo);
    }

    return result;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type CreateUserPayload = {
  firstName: string;
  lastName?: string;
  email?: string;
  phone?: string;
  creditCard?: string;
};

export const createUserAsync = createAsyncThunk<
  User,
  CreateUserPayload,
  ThunkApi
>('users/createUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `/user/create`,
      data: payload,
    });

    return response.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

type DeleteUserPayload = { userId: string };

export const deleteUserAsync = createAsyncThunk<
  User,
  DeleteUserPayload,
  ThunkApi
>('users/deleteUser', async (payload, { rejectWithValue }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/user/${payload.userId}`,
    });

    return response.data.data;
  } catch (err) {
    return rejectWithValue(err.response);
  }
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsersAsync.fulfilled, (state, { payload }) => {
      return payload;
    });
    builder.addCase(changeAvatarAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((user) => user._id === payload._id);
      if (index !== -1) state[index].avatar = payload.avatar;
    });
    builder.addCase(editUserInfoAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((user) => user._id === payload._id);
      if (index !== -1) state[index] = payload;
    });
    builder.addCase(createUserAsync.fulfilled, (state, { payload }) => {
      state.push(payload);
    });
    builder.addCase(deleteUserAsync.fulfilled, (state, { payload }) => {
      const index = state.findIndex((user) => user._id === payload._id);
      if (index !== -1) state[index].isDeleted = true;
    });
  },
});

// export const {} = usersSlice.actions;

export default usersSlice.reducer;
