import React from 'react';
import styled from 'styled-components';
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import UserEditProfile from './user-profile-form/UserEditProfile';
import DeleteUser from './DeleteUser';

const Wrapper = styled.div`
  margin-left: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  flex-direction: column;
  padding: 24px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 20px;
  margin-bottom: 24px;

  @media screen and (max-width: 960px) {
    margin-left: 0;
  }
`;

const Title = styled.h3`
  color: #545970;
  font-weight: 600;
  margin-bottom: 16px;
  line-height: 1;
  font-size: 16px;
  text-transform: uppercase;
  background-color: #24252d;
  padding: 8px;
  border-radius: 4px;
  align-self: flex-start;
`;

const List = styled.ul`
  margin-bottom: 32px;
`;

const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
`;

const Info = styled.div`
  font-weight: 500;
`;
const Label = styled.p`
  color: #545970;
  font-size: 14px;
`;
const Value = styled.p``;

const formatCredit = (string) => {
  return string.replace(/\d{4}(?=.)/g, '$& ');
};

function UserProfileInfo({ user, auth }) {
  return (
    <Wrapper>
      <Title>User profile</Title>
      <List>
        <Item>
          <IconWrapper>
            <AlternateEmailRoundedIcon style={{ color: '#545970' }} />
          </IconWrapper>
          <Info>
            <Value>{user.email || '-'}</Value>
            <Label>Email</Label>
          </Info>
        </Item>

        <Item>
          <IconWrapper>
            <PhoneRoundedIcon style={{ color: '#545970' }} />
          </IconWrapper>
          <Info>
            <Value>{user.phone || '---'}</Value>
            <Label>Phone number</Label>
          </Info>
        </Item>
        <Item>
          <IconWrapper>
            <CreditCardRoundedIcon style={{ color: '#545970' }} />
          </IconWrapper>
          <Info>
            <Value>
              {user.creditCard ? formatCredit(user.creditCard) : '---'}
            </Value>
            <Label>Credit card</Label>
          </Info>
        </Item>
        {user.telegramLogin && (
          <Item>
            <IconWrapper>
              <SendRoundedIcon style={{ color: '#545970' }} />
            </IconWrapper>
            <Info>
              <Value>{user.telegramLogin}</Value>
              <Label>Telegram login</Label>
            </Info>
          </Item>
        )}
      </List>
      <UserEditProfile user={user} auth={auth} />
      {auth.isAdmin && !user.isDeleted && !user.isAdmin && (
        <DeleteUser userId={user._id} />
      )}
    </Wrapper>
  );
}

export default UserProfileInfo;
