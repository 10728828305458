import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store';
import Table from './Table';
import FilterBar from './FilterBar';
import { FilterState } from './types';
import { fetchAllTasksAsync, fetchArchiveTasksAsync } from '../../store/slices/taskboardSlice';

const initialState = {
  executor: null,
  startTime: Date.now() - 604800000,
  endTime: Date.now(),
};

type Action =
  | { type: 'SELECT_USER'; id: string }
  | { type: 'SELECT_ALL_USER' }
  | { type: 'SELECT_TIME'; startTime: number; endTime: number };

function filterReducer(state: FilterState, action: Action) {
  switch (action.type) {
    case 'SELECT_USER': {
      return {
        ...state,
        executor: action.id,
      };
    }
    case 'SELECT_ALL_USER': {
      return {
        ...state,
        executor: null,
      };
    }
    case 'SELECT_TIME': {
      return {
        ...state,
        startTime: action.startTime || state.startTime,
        endTime: action.endTime || state.endTime,
      };
    }
    default: {
      throw new Error(`Unhandled action type: "${action}" in ModalProvider`);
    }
  }
}

function Archive() {
  // dispatch({ type: 'FINISH_STEP', payload });
  const auth = useAppSelector((state) => state.auth);
  const [filterState, dispatch] = React.useReducer(filterReducer, initialState);
  const fetchDispatch = useAppDispatch();

  const selectUser = (id) => {
    dispatch({ type: 'SELECT_USER', id });
  };
  const selectAll = () => {
    dispatch({ type: 'SELECT_ALL_USER' });
  };

  const selectTime = (time) => {
    dispatch({ type: 'SELECT_TIME', ...time });
  };

  // Create effect that will fetch all tasks when component is mounted or when filterState changes
  useEffect(() => {
    if (auth._id) {
      fetchDispatch(fetchArchiveTasksAsync(filterState))
        .then();
    }
  }, [auth, fetchDispatch, filterState]);

  const tasks = useAppSelector((state) => state.taskboard.archive);
  const users = useAppSelector((state) => state.users);

  return (
    <div>
      <FilterBar
        users={users}
        selectUser={selectUser}
        selectAll={selectAll}
        selectTime={selectTime}
        filterState={filterState}
      />
      <Table users={users} tasks={tasks} filterState={filterState} />
    </div>
  );
}

export default Archive;
