import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { motion } from 'framer-motion';
import { saveAs } from 'file-saver';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styled from 'styled-components';
import { User } from '../../store/slices/usersSlice';
import { DEFAULT_AVATAR_PLACEHOLDER } from '../../lib/services/images';
import { useAppSelector } from '../../store';
import { FilterState } from './types';
import axios from '../../lib/api/axios';

const Wrapper = styled.nav`
  display: flex;
  margin-bottom: 24px;
  gap: 16px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`;

const Select = styled.button`
  text-align: center;
  padding: 8px 16px;
  color: #fff;
  height: 52px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  border: 1px solid #b9375e;

  span {
    margin-right: 8px;
  }
`;

const ExecutorWrapper = styled(Select)`
  position: relative;
  justify-content: space-between;
  width: 240px;

  @media screen and (max-width: 960px) {
    width: 100%;
  }
`;

const UserSelector = styled(motion.ul)`
  cursor: default;
  position: absolute;
  padding: 12px;
  left: 0;
  width: 100%;
  top: 120%;
  border-radius: 8px;
  background-color: #2a2d39;
  box-shadow: 0px 12.5216px 56.4722px rgba(0, 0, 0, 0.292907),
    0px 6.6501px 29.992px rgba(0, 0, 0, 0.193357),
    0px 2.76726px 12.4803px rgba(0, 0, 0, 0.100427);
  border-radius: 8px;
  overflow: auto;
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;

  :hover {
    background-color: #232530;
  }
`;

const Avatar = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
`;

const Name = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const Selecteduser = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
`;

const SelectAll = styled.p`
  margin-top: 16px;
  background-color: #232530;
  padding: 8px;
  cursor: pointer;
  border-radius: 8px;
`;

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiInputBase-root {
    color: var(--white);
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: var(--primary);
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: var(--primary);
  }

  .MuiIconButton-root {
    color: var(--white);
  }
`;

const DatePickersWrapper = styled.div`
  display: flex;
  margin-left: auto;

  > div:first-child {
    margin-right: 16px;
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    margin-top: 24px;
    margin-left: 0;

    > div:first-child {
      margin-bottom: 16px;
      margin-right: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  &[disabled] {
    color: #fff;
    background-color: #b9375e;
    cursor: default;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -o-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -moz-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    -webkit-animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  @keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-o-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-moz-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
  @-webkit-keyframes pulse { 0%, 100% {opacity: .5;} 50% {opacity: .7;}}
`;

interface FilterBarProps {
  users: User[];
  filterState: FilterState;
  selectUser: (id: string) => void;
  selectAll: () => void;
  selectTime: ({
    startTime,
    endTime,
  }: {
    startTime?: number;
    endTime?: number;
  }) => void;
}

const FilterBar: React.FC<FilterBarProps> = ({
  users,
  filterState,
  selectUser,
  selectAll,
  selectTime,
}) => {
  const [fromDate, handleFromDateChange] = useState(new Date(filterState.startTime));
  const [toDate, handleToDateChange] = useState(new Date(filterState.endTime));
  const [userSelectOpen, setUserSelectOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const auth = useAppSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  const toogleUserSelect = () => {
    setUserSelectOpen((prev) => !prev);
  };

  const handleSelect = (user) => () => {
    setSelectedUser(user);
    selectUser(user._id);
  };

  const handleAllSelect = () => {
    setSelectedUser(null);
    selectAll();
  };

  const handleStartDate = (date) => {
    const startTime = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    selectTime({ startTime });
  };

  const handleEndDate = (date) => {
    const timestamp = new Date(
      date.replace(/\//g, '-').split('-').reverse().join('-')
    ).getTime();
    const endTime = timestamp + 86400000;
    // return timestamp in milliseconds of the next date at 00:00:00
    selectTime({ endTime });
  };

  const getDownloadFile = async () => {
    return axios.get(`/task/export-archive?from=${fromDate.getTime()}&to=${toDate.getTime()}${selectedUser?._id ? `&user=${selectedUser._id}` : ''}`, {
      responseType: 'blob',
    });
  };

  const downloadFile = () => {
    setLoading(true);

    getDownloadFile()
      .then((response) => saveAs(response.data, 'archive.xlsx'))
      .finally(() => setLoading(false));
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Wrapper>
        {auth.isAdmin && (
          <ExecutorWrapper onClick={toogleUserSelect}>
            {selectedUser ? (
              <Selecteduser>
                <Avatar
                  src={selectedUser.avatar || DEFAULT_AVATAR_PLACEHOLDER}
                />
                <Name>{selectedUser.firstName}</Name>
              </Selecteduser>
            ) : (
              <span>Author</span>
            )}
            <ExpandMoreIcon />
            {userSelectOpen && (
              <UserSelector
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
              >
                {users.map((user) => {
                  if (user.isDeleted) {
                    return null;
                  }
                  return (
                    <UserItem onClick={handleSelect(user)} key={user._id}>
                      <Avatar src={user.avatar || DEFAULT_AVATAR_PLACEHOLDER} />
                      <Name>
                        {user.firstName} {user.lastName}
                      </Name>
                    </UserItem>
                  );
                })}
                <SelectAll onClick={handleAllSelect} key="select all">
                  Select All
                </SelectAll>
              </UserSelector>
            )}
          </ExecutorWrapper>
        )}

        <DatePickersWrapper>
          <StyledKeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="From"
            format="dd/MM/yyyy"
            value={fromDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleStartDate(value);
              handleFromDateChange(date);
            }}
          />
          <StyledKeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="To"
            format="dd/MM/yyyy"
            value={toDate}
            InputAdornmentProps={{ position: 'start' }}
            onChange={(date, value) => {
              handleEndDate(value);
              handleToDateChange(date);
            }}
          />
        </DatePickersWrapper>

        <StyledButton
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          onClick={downloadFile}
          disabled={loading}
        >
          Download
        </StyledButton>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

export default FilterBar;
